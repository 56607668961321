.dashboard-header {
  display: flex;
  align-items: center;
}

.dashboard-logo {
  width: 200px;
  font-size: 22px;
  font-weight: bold;
  color: white;
}

.dashboard-menu {
  flex: 1;
  min-width: 0;
}

.dashboard-right-menu {
  width: 170px;
}

.dashboard-footer {
  text-align: center;
  font-weight: bolder;
}

.dashboard-footer-text {
  color: black;
  transition: color 0.3s;
}

.dashboard-footer-text:hover {
  color: blue;
}
